const config = {
  apiKey: 'AIzaSyBeVHiSvEaiLEAj5UWue16AbFlWNwTLLyY',
  authDomain: 'nasa-pictures-4c49e.firebaseapp.com',
  projectId: 'nasa-pictures-4c49e',
  storageBucket: 'nasa-pictures-4c49e.appspot.com',
  messagingSenderId: '285216303249',
  appId: '1:285216303249:web:737cf15e4aba928ccdf730',
};

export default config;
